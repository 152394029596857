import React, { useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './Rotas.css'

import PgLives from './Paginas/PgLives'
import PgSignIn from './Paginas/PgSignIn'
import PgSignUp from './Paginas/PgSignUp'
import PgUsuario from './Paginas/PgUsuario'
import PgCanal from './Paginas/PgCanal' 


import PgaSobre from './PaginasAux/PgaSobre'
import PgaSuporte from './PaginasAux/PgaSuporte'
import PgaTermos from './PaginasAux/PgaTermos'
import PgaPoliticas from './PaginasAux/PgaPoliticas'
 

import PgaAssinatura from './PaginasAux/PgaAssinatura'

import { global } from './DataAPI/global'

import { MeuContexto } from "./App"



import NavCabecalho from './Navegacao/NavCabecalho'

import NavRodape from './Navegacao/NavRodape'


function Rotas() {

  const { logado, setlogado, gett, setgett } = useContext(MeuContexto)


  useEffect(
    () => {




      if (!gett) {

        try {

          global.token = localStorage.getItem('@meupastortv:token')

        } catch (error) {
          global.token = null

        }

        if (global.token === null) {
          setlogado(false)

        } else {

          setlogado(true)

        }

        ///global.token, ' pega token')

        setgett(true)

      } else {


        if (logado) {
          ///console.log(global.token, '-------- grava token')

          try {
            localStorage.setItem('@meupastortv:token', global.token);
          } catch (error) {

          }
        }
        if (!logado) {

          ///   console.log(global.token, '-------- limpa token')
          global.token = null
          try {
            localStorage.removeItem('@meupastortv:token');

          } catch (error) {

          }

        }

      }

    },
    [logado, setlogado, gett, setgett]
  )





  return (

    <Router>
      <div>

        <NavCabecalho />


        <div className="conteudo">

          <div className="conteudo2">

            <Switch>
              <Route exact path="/">
                <PgLives />
              </Route>
              <Route path="/SignIn">
                <PgSignIn />
              </Route>
              <Route path="/SignUp">
                <PgSignUp />
              </Route>
              <Route path="/Usuario">
                <PgUsuario />
              </Route>
           
              

              <Route path="/Canal/:liveId">
                <PgCanal />
              </Route>


              <Route path="/Sobre">
                <PgaSobre />
              </Route>
              <Route path="/Suporte">
                <PgaSuporte />
              </Route>
              <Route path="/Termos">
                <PgaTermos />
              </Route>
              <Route path="/Politicas">
                <PgaPoliticas />
              </Route>
              <Route path="/Assinatura">
                <PgaAssinatura />
              </Route>
            </Switch>


          </div>

        </div>


        <NavRodape />
      </div>



    </Router>
  );
}


export default Rotas;

