import React from 'react';

import './PgaAssinatura.css';

function Plano({ titulo, preco, descricao }) {
    return (
      <div className="plano">
        <h2 className="xplanoh2">{titulo}</h2>
        <p  className="xplanop">{preco}</p>
        <button className="xbutton1">{descricao}</button>
      </div>
    );
  }

function PgAssinatura() {

  return (   <div className="container13">
        <div className="container12">
    <div className="container11">
    <h1 className="xh1" >Escolha seu Plano de Assinatura</h1>
    <div className="planos">
      <Plano
        titulo="Plano Mensal"
        preco="R$ 29,90 / mês" 
        descricao="Assinar por 1 mês"
      />
      <Plano
        titulo="Plano Trimestral"
        preco="R$ 79,90 / 3 meses"  
        descricao="Assinar por 3 meses"
      />
      <Plano
        titulo="Plano Anual"
        preco="R$ 299,90 / ano" 
        descricao="Assinar por 1 ano"
      />
    </div>
  </div>
  </div>
  </div>
    );
}

export default PgAssinatura;