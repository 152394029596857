import React, { useEffect, useContext, useRef, useState } from 'react';


import Hls from "hls.js";

const VideoPlayer = ({ videoSrc }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {

      var config = {

        autoStartLoad: true,
        startPosition: -1,
        enableWorker: true,

        liveBackBufferLength: 4000,
        liveSyncDurationCount: 10,


      };

      const hls = new Hls(config);
      hls.loadSource(videoSrc);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play();
      });

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoSrc;
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play();
      });
    }
  }, [videoSrc]);
 

  return <video
    style={{ width: '100%',maxHeight: '18rem',borderRadius:'16px' }}
    ref={videoRef}
    controls={false}
    autoPlay={true}
    playsInline
    webkit-playsinline="true"
    crossOrigin="anonymous"
    muted
  />;
};

export default VideoPlayer;
