import api from './apifatlives';

export const data_fatlives = {



    async extratoCX(page, idpartida) {

        const response = await api.get(`fatlivescx/${page}/${idpartida}`);
        if (response.data) { return response.data } else { return null }


    },



    async extratoPago(idartista, idpublico) {

        ///  console.log(idartista, idpublico)

        const response = await api.get(`fatlivespago/${idartista}/${idpublico}`)

        if (response.data) { return response.data } else { return null }

    },

    async Pagamento(data) {

        const response = await api.post('fatlivespagar', data);
        if (response.data) { return response.data } else { return null }

    },

    async pagariugu(data) {

        const response = await api.post('iugupagar', data);
        if (response.data) { return response.data } else { return null }

    },


    async pagariugupix(data) {

        try {
            const response = await api.post('iugupagarpix', data);
            if (response.data) { return response.data } else { return null }
        } catch (error) {
            return null

        }
    },

    

    async localizarPIX(artistaid, publicoid) {

        try {
            const response = await api.get(`fatpix2/${artistaid}/${publicoid}`);
            if (response.data) { return response.data } else { return null }

        } catch (error) {
            return null

        }

    },


    async Recebimento(data) {
        ///  console.log(data,'recebimento')
        const response = await api.post('fatlivesreceber', data);

        ///   console.log(response.data,' ------- response recebimento')

        if (response.data) { return response.data } else { return null }

    },

    async Tips(data) {
        /// console.log(data,'tips')
        const response = await api.post('fatlivestips', data);

        ///    console.log(response.data,' ------- response tips')

        if (response.data) { return response.data } else { return null }

    },

};
