import React, { useContext } from 'react';
import { Link } from "react-router-dom";


import './NavCabecalho.css'
import logo from '../img/logo.png'
import { MeuContexto } from "../App"

function NavCabecalho() {
    const { logado, setlogado, username } = useContext(MeuContexto)


    function _sair() {

        setlogado(false)
    }

    return (
        <div className="cabecalho">
            <div className="navbox">  </div>
            <div>

                <Link to="/" className="navlink"  >
                    <img src={logo} alt="logo" className="logo" />

                </Link>
            </div>
            <div className="navbox" >
                <div className="navbar">
                    {(!logado) ? (<>
                        <div>
                            <Link className="btsair" to="/SignIn">ENTRAR</Link>
                        </div>

                        <div >
                            <Link className="btcadastrar" to="/SignUp">Cadastrar-se</Link>
                        </div>
                    </>) : (<>
                        <div >
                            <Link className="btcadastrar" to="/Usuario">{username}</Link>
                        </div>
                        <div >
                            <button className="btsair" onClick={() => { _sair() }}>Sair</button>
                        </div>

                    </>)}
                </div>
            </div>
        </div>


    )
        ;
}

export default NavCabecalho;