import React, { useState, useContext } from 'react'
import { useHistory, Link } from "react-router-dom"

import './PgSignIn.css';

import { MeuContexto } from "../App"
import { global } from '../DataAPI/global'
import { data_user } from '../DataAPI/datauser'

import { data_email } from '../DataAPI/dataemail';

import { validar } from '../DataAPI/apiValidar'

///import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PgSignIn() {


    let history = useHistory();

    const { setlogado, setusername } = useContext(MeuContexto)

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')

    const [vemail, setvemail] = useState(true)
    const [vpassword, setvpassword] = useState(true)


    function _validar() {


        var retorno = true

        if (!validar._email(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(password).valido) { setvpassword(false); retorno = false } else { setvpassword(true) }


        return retorno

    }

    async function _Logar(e) {
        e.preventDefault();
        if (_validar()) {

            try {
                const resposta2 = await data_user.conectar({ email, password });
                ///    console.log(resposta2)
                if (resposta2) {
                    global.token = resposta2.token
                    try {
                        localStorage.setItem('@meupastortv:token', global.token);

                    } catch (error) {

                    }

                    if (_validarme()) {

                        setlogado(true)
                        history.goBack()
                    } else {
                        setlogado(false)
                        history.push("/")
                    }

                }
            } catch (error) {
                toast.error(

                    'Favor verificar suas informações!'
                );
            }







        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }
    async function _validarme() {
        const respostame = await data_user.me();
        if (respostame) {

            setusername(respostame.username)

            ///  console.log(respostame)
            if (respostame.banido) { return false } else { return true }
        }
        return false

    }



    function _validarRecuperarSenha() {


        if (!validar._email(email).valido) return false
        if (!validar._requerido(email).valido) return false


        return true
    }


    async function _recuperarsenha() {


        if (_validarRecuperarSenha()) {


            const resp = await data_user.novasenha(email)

            if (resp) {

                data_email.rsenha(email, resp.senha)

            }
            toast.success(

                'E-mail enviado com a nova senha!'
            );

        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }

    /*
        async function responseFacebook(response) {
    
            if (response) {
    
                if (response.accessToken) {
    
    
                    const resposta = await data_user.facebook({ faceid: response.id, faceemail: response.email, facename: response.name });
    
                    //////console.log(resposta, 'resposta face'        )
    
                    if (resposta) {
    
    
                        const resposta2 = await data_user.conectar(resposta);
                        ///    console.log(resposta2)
                        if (resposta2) {
                            global.token = resposta2.token
                            try {
                                localStorage.setItem('@meupastortv:token', global.token);
    
                            } catch (error) {
    
                            }
    
                            if (_validarme()) {
    
                                setlogado(true)
                                history.goBack()
                            } else {
                                setlogado(false)
                                history.push("/")
                            }
    
                        }
    
                    }
    
    
    
                }
    
    
            }
    
    
            //////console.log(response)
    
    
    
    
        }
    
        */
    function componentClicked() { }


    return (
        <div className={"margem"}>
        <div className={"ulabel1"}>

            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />
            <form className={"uform"} onSubmit={_Logar} autoComplete="off">

                <h2 className={"ulabel1"}  >Entrar</h2>


                <label className={"ulabel"} for="email">Email:<span className={"uspan"}>*</span></label>
                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="seu@email.com.br"
                    name="email"
                    type="email"
                    value={email}
                    onChange={e => setemail(e.target.value)} />
                {(!vemail) ? (<><p className={"uspan ualinha"} for="email">Verificar Email</p></>) : (<></>)}

                <label className={"ulabel"} for="password">Senha:<span className={"uspan"}>*</span></label>
                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="******"
                    name="password"
                    type="password"
                    value={password}
                    onChange={e => setpassword(e.target.value)} />
                {(!vpassword) ? (<><p className={"uspan ualinha"} for="password">Verificar Senha</p></>) : (<></>)}

                <br></br>

                <button className="btsalvar" type="submit" value="Submit">Entrar</button>
                {/**
                <FacebookLogin
                    appId={global.brl.REACT_APP_FACE}
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={componentClicked}
                    callback={responseFacebook}
                    redirectUri='https://meupastor.tv'
                    isMobile={true}
                    disableMobileRedirect={true}
                    cookie={true}
                    render={renderProps => (
                        <button className="btface" onClick={renderProps.onClick}>  Entrar com Facebook</button>
                    )}
                />
                 */}
                <button className="btcancelar" type="button" onClick={() => _recuperarsenha()} value="singup">Recuperar Senha</button>

                <button className="btcancelar" type="button" onClick={() => { history.push("/SignUp") }} value="singup">Criar Conta</button>

            </form>

        </div>
        </div>
    );
}

export default PgSignIn;